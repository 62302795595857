.image-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
}

.image-popup.closing {
  animation: fadeOut 0.3s ease-in-out;
}

.popup-content {
  background: white;
  text-align: center;
  position: relative;
  max-width: 75%;
  max-height: 75%;
  animation: scaleIn 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black; /* Set background color to black for the bars */
}

.popup-image {
  max-width: 100%;
  max-height: 60vh; /* Limit the image height to 60% of the viewport height */
  object-fit: contain; /* Ensure the image scales correctly */
}

.popup-details {
  position: absolute;
  bottom: -50px;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background for readability */
  padding: 5px;
  border-radius: 3px;
}

.popup-details p {
  margin: 2px 0;
}

/* Ensure the close button is above the top bar */
.close {
  position: absolute;
  top: 50px;
  right: 20px;
  font-size: 30px;
  color: white;
  cursor: pointer;
}

.closeDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}