.about-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
  font-family: 'Playfair Display', serif;
}

.about-container h1 {
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
}

.about-container p {
  font-size: 24px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
}