body {
  background-color: white; /* Set background color of the page to white */
  margin: 0;
  font-family: 'Playfair Display', serif;
}

.App {
  text-align: center;
}

.top-bar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  color: white;
  z-index: 1000; /* Ensure the top bar is above other content */
}

.logo {
  font-size: 24px;
  white-space: nowrap; /* Prevent word wrapping */
}

.nav-menu {
  right: 40px;
  top: 4px;
  position: absolute;
}

.menu-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.menu-links {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #333;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.menu-links a {
  color: white;
  text-decoration: none;
  margin: 5px 0;
  font-size: 18px;
}

.menu-links a:hover {
  text-decoration: underline;
}