.home-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Adjust height to fill the entire screen */
    background-color: white;
    overflow: hidden; /* Ensure no overflow */
  }
  
  .home-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the entire container */
  }
  
  .home-details {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background for readability */
    padding: 5px;
    border-radius: 3px;
  }
  
  .home-details p {
    margin: 0;
  }