.cv-container {
    padding: 20px;
    max-width: 800px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    text-align: left;
    font-family: 'Playfair Display', serif;
  }
  
  .cv-container h1 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .cv-container p {
    font-size: 18px;
    margin: 5px 0;
  }
  
  .cv-container h2 {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .cv-container ul {
    list-style-type: none;
    padding: 0;
  }
  
  .cv-container li {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.6;
  }