.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: white; /* Set background color of the grid to white */
  padding: 6%;
}

.loading-spinner {
  text-align: center;
  padding: 50px;
  font-size: 24px;
  color: grey;
}