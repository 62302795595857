.image-card {
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    transition: transform 0.2s;
    overflow: hidden; /* Contain the hover animation within the box */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px; /* Set a fixed width for the square container */
    height: 200px; /* Set a fixed height for the square container */
    background-color: rgb(0, 0, 0); /* Set background color to black for the bars */
    border: 2px solid rgb(0, 0, 0); /* Add grey border around each image square */
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .image-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Ensure the image scales correctly within the container */
  }
  
  .image-card:hover {
    transform: scale(1.05); /* Slightly increase the scale on hover */
  }