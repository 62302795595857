.contact-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
  font-family: 'Playfair Display', serif;
}

.contact-container h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.contact-container p {
  font-size: 24px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.contact-container a {
  color: #333;
  text-decoration: none;
}

.contact-container a:hover {
  text-decoration: underline;
}